import request from '@/utils/request'

export function getLingBan(params) {
    return request({
        url: '/zhfe/lingban/list',
        method: 'GET',
        params
    })
}

export function addLingBan(data) {
    return request({
        url: '/zhfe/lingban',
        method: 'POST',
        data
    })
}

export function saveLingBan(data) {
    return request({
        url: '/zhfe/lingban',
        method: 'PUT',
        data
    })
}

export function deleteLingBan(id) {
    return request({
        url: `/zhfe/lingban/${id}`,
        method: 'DELETE'
    })
}

export function deletelistData(query) {
    return request({
        url: `/system/data/${query}`,
        method: 'delete'
    })
}

export function addlistData(query) {
    return request({
        url: '/system/data',
        method: 'post',
        data: query
    })
}