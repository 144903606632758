<template>
	<div class="main">
		<div class="header">
			<img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
			<img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
			<span class="header-txt">智惠妇儿</span>
			<!-- 关闭 刷新 -->
			<div class="idx-middlesx-box3">
				<div class="idx-middle-1">
					<img src="@/assets/image/zhfe/return.png" @click="getback" />
					<img src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
				</div>
			</div>
		</div>
		<div class="time-box">
			<Date></Date>
		</div>
		<div class="title">领办项目</div>
		<div class="container">
			<div class="awards-filter-wrap">
				<el-form :inline="true" :model="queryParams" class="demo-form-inline">
					<el-form-item label="项目名称：">
						<el-input v-model="queryParams.title" placeholder="请输入项目名称"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">搜索</el-button>
						<el-button type="primary" class="other" @click="onReset">重置</el-button>
					</el-form-item>
				</el-form>
				<div class="operate">
					<el-button type="primary" class="other" @click="handleEdit">+新增</el-button>
					<!-- <el-button type="primary" @click="onImport">导入</el-button> -->
				</div>
			</div>
			<div class="table-wrap">
				<el-table
					:data="lingbanList"
					stripe
					:style="{
						width: '100%',
						maxHeight: `${windowHeight > 1200 ? 68 : 60}vh`,
						overflowY: ' auto',
					}"
					header-align="center"
					align="center"
					:cell-style="{ background: 'revert' }"
				>
					<el-table-column type="index" label="序号" width="80" align="center"> </el-table-column>
					<el-table-column prop="title" label="项目名称" align="center"> </el-table-column>
					<el-table-column prop="ownerName" label="领办人" align="center"> </el-table-column>
					<el-table-column prop="lingbanIntro" label="项目介绍" align="center"> </el-table-column>
					<el-table-column prop="lingbanDesc" label="服务成效" align="center"> </el-table-column>
					<!-- <el-table-column label="图片" align="center" width="80">
						<template slot-scope="scope">
							<el-button type="text" class="edit" @click="handleImageEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column> -->
					<el-table-column label="操作" align="center" width="120">
						<template slot-scope="scope">
							<el-button type="text" class="delete" @click="handleDelete(scope.row)">删除</el-button>
							｜
							<el-button type="text" class="edit" @click="handleEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-wrap">
				<el-pagination
					background
					@size-change="handleSizeChange"
					layout="total,sizes,prev, pager, next"
					:page-sizes="[10, 20, 50, 100]"
					@current-change="handleCurrentChange"
					:total="total"
					:current-page="queryParams.pageNum"
					:page-size="queryParams.pageSize"
					:prev-text="'上一页'"
					:next-text="'下一页'"
					popper-class="pagination-select"
				>
				</el-pagination>
			</div>
		</div>
		<el-dialog
			:visible.sync="dialogVisible"
			title=""
			class="import-dialog"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<el-upload
				action=""
				list-type="picture-card"
				:on-remove="handleRemove"
				:accept="accept"
				:http-request="uploadHttp"
				:file-list="fileList"
				:before-upload="handleBeforeUpload"
				:on-error="handleUploadError"
				:before-remove="handleRemove"
				:limit="1"
			>
				<i class="el-icon-plus"></i>
				<div class="el-upload__tip" slot="tip">
					只能上传JPG/PNG格式文件，最多只能上传10张图片，单个图片不能超过150kb
				</div>
			</el-upload>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleImageConfirm">确 定</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="dialogVisible = false" alt="" />
		</el-dialog>
		<el-dialog
			:visible.sync="editVisible"
			title=""
			class="import-dialog"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-title">{{ dialogTitle }}项目</div>
			<el-form
				ref="form"
				:inline="true"
				label-position="right"
				label-width="120px"
				:model="form"
				:rules="rules"
				class="demo-form-inline dialog-form-inline"
			>
				<el-form-item label="项目名称" prop="title">
					<el-input v-model="form.title" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="领办人" prop="ownerName">
					<el-input v-model="form.ownerName" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="项目介绍" prop="lingbanIntro">
					<el-input type="textarea" :rows="2" :autosize="true" v-model="form.lingbanIntro" placeholder=""></el-input>
				</el-form-item>
				<el-form-item label="服务成效" prop="lingbanDesc">
					<el-input type="textarea" :autosize="true" v-model="form.lingbanDesc" placeholder=""></el-input>
				</el-form-item>
				<el-form-item
					label="项目图片"
					prop="fileList"
					:rules="{ required: true, validator: checkfilelist, trigger: 'blur' }"
				>
					<el-upload
						action=""
						list-type="picture-card"
						:on-remove="handleRemove"
						:accept="accept"
						:http-request="uploadHttp"
						:file-list="fileList"
						:before-upload="handleBeforeUpload"
						:on-error="handleUploadError"
						:before-remove="handleRemove"
						class="picture-card"
						:limit="10"
					>
						<i class="el-icon-plus"></i>
						<div class="el-upload__tip" slot="tip">
							只能上传JPG/PNG格式文件，最多只能上传10张图片，单个图片不能超过150kb
						</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleConfirm">确定{{ dialogTitle }}</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="editVisible = false" alt="" />
		</el-dialog>
		<el-dialog
			title=""
			class="delete-dialog"
			:visible.sync="delete_dialog"
			width="780px"
			:append-to-body="true"
			:close-on-click-modal="false"
		>
			<div class="dialog-title">系统提示</div>
			<div class="dialog-content">是否确认删除“{{ dataDetail && dataDetail.title }}”数据项？</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="delete_dialog = false">取 消</el-button>
				<el-button type="primary" @click="handleDeleteConfirm">确 定</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="delete_dialog = false" alt="" />
		</el-dialog>
	</div>
</template>
<script>
import Date from '@/views/commonComponents/date.vue';
import { getLingBan, addLingBan, saveLingBan, deleteLingBan, deletelistData, addlistData } from '@/api/lingban';
import { listData } from '@/api/dtdata';
import { uploadimg } from '@/api/home';
import { Validate, CatchError } from '@/utils/decorator';
import { download as downloadFile, resetPage } from '@/utils/index.js';

export default {
	components: { Date },
	data() {
		return {
			lingbanList: [],
			total: 0,
			queryParams: {
				pageNum: 1,
				pageSize: 20,
				title: '',
			},
			dialogVisible: false,
			accept: '.jpg,.png',
			fileList: [],
			dataTyp: '',
			dataModule: 'lbxm',
			editVisible: false,
			form: {
				descTitle: '服务成效',
				introTitle: '项目介绍',
				lingbanDesc: '',
				lingbanIntro: '',
				ownerName: '',
				title: '',
				fileList: [],
			},
			rules: {
				title: [{ required: true, message: '请输入项目名称', trigger: 'blur' }],
				ownerName: [{ required: true, message: '请输入领办人', trigger: 'blur' }],
				lingbanIntro: [{ required: true, message: '请输入项目介绍', trigger: 'blur' }],
				lingbanDesc: [{ required: true, message: '请输入服务成效', trigger: 'blur' }],
			},
			dialogTitle: '',
			dataDetail: null,
			delete_dialog: false,
			windowHeight: 0,
		};
	},
	methods: {
		handleDeleteConfirm() {
			deleteLingBan(this.dataDetail.id).then((res) => {
				if (res.code == 200) {
					this.queryParams.pageNum = resetPage(this.queryParams.pageNum, this.queryParams.pageSize, this.total, 1);
					this.getLingBan();
					this.delete_dialog = false;
					// this.$router.go(0); //刷新当前页面
				}
			});
		},
		checkfilelist(rule, value, callback) {
			if (this.fileList.length === 0) {
				return callback(new Error('请上传项目图片'));
			} else {
				callback();
			}
		},
		onSubmit() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams.pageNum = 1;
			this.getLingBan();
		},
		onReset() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams = {
				pageNum: 1,
				pageSize: 20,
				title: '',
			};
			this.getLingBan();
		},
		onAdd() {},
		refresh() {
			this.$router.go(0); //刷新当前页面
		},
		getback() {
			this.$router.go(-1); //刷新当前页面
		},
		handleSizeChange(e) {
			this.queryParams.pageSize = e;
			this.getListData();
		},
		handleCurrentChange(e) {
			this.queryParams.pageNum = e;
			this.getListData();
		},
		@Validate('form')
		@CatchError()
		async handleConfirm() {
			const {
				data: { id },
			} = this.form.id ? await saveLingBan(this.form) : await addLingBan(this.form);
			this.handleImageConfirm(id);
			this.getLingBan();
			this.editVisible = false;
		},
		async uploadHttp({ file }) {
			const formData = new FormData();
			formData.append('file', file);
			const { fileName, code } = await uploadimg(formData);
			const item = {
				dataDetailed: fileName,
				dataModule: this.dataModule,
				dataTyp: this.dataTyp,
				url: fileName,
				name: fileName,
			};
			if (code === 200) {
				this.fileList.push(item);
				this.loading.close();
				this.$refs['form'].validateField('fileList');
			}
		},
		async handleImageConfirm(id) {
			this.dataTyp = `lbxm-pic-${id}`;
			const { rows } = await listData({ dataModule: this.dataModule, dataTyp: this.dataTyp });
			const ids = rows.map((item) => item.dataId).toString();
			ids.length > 0 && deletelistData(ids);
			await Promise.all(
				this.fileList.map((item) =>
					addlistData({ dataDetailed: item.dataDetailed, dataModule: item.dataModule, dataTyp: this.dataTyp })
				)
			);
			this.fileList = [];
			this.dialogVisible = false;
		},
		handleUploadError() {
			this.$message({
				type: 'error',
				message: '上传失败',
			});
			this.loading.close();
		},
		handleBeforeUpload(file) {
			this.loading = this.$loading({
				lock: true,
				text: '上传中',
				background: 'rgba(0, 0, 0, 0.7)',
			});
			const accept = this.accept.split(',');
			let type = file.name.split('.');
			type = '.' + type[type.length - 1];
			const isType = accept.some((item) => {
				return type === item;
			});
			if (!isType) {
				this.$message.error(`上传文件只能是 ${this.accept}格式`);
				this.loading.close();
			}
			const isLt1GB = file.size / 1024 < 150;
			if (!isLt1GB) {
				this.$message.error('上传文件的大小不能超过 150KB!');
				this.loading.close();
			}
			return isType && isLt1GB;
		},
		handleRemove(file, fileList) {
			this.fileList = fileList;
			this.$refs['form'].validateField('fileList');
		},
		async getLingBan() {
			const { rows, total } = await getLingBan(this.queryParams);
			this.lingbanList = rows;
			this.total = total;
		},
		// 图片编辑
		handleImageEdit(row) {
			const { id } = row;
			this.dialogVisible = true;
			this.getImages(id);
		},
		async handleDelete(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { id, title } = row;
			this.dataDetail = row;
			this.delete_dialog = true;
			return;
			try {
				await this.$confirm(`此操作将永久删除“${title}”这条记录, 是否继续?`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				});
				await deleteLingBan(id);
				this.getLingBan();
			} catch {
				this.$message({
					type: 'info',
					message: '已取消删除',
				});
			}
		},
		handleEdit(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { id } = row;
			this.form = { ...row };
			if (!id) {
				this.fileList = [];
			}
			this.dialogTitle = id ? '修改' : '新增';
			id && this.getImages(id);
			this.editVisible = true;
			this.$nextTick(() => {
				this.$refs['form'].clearValidate();
			});
		},
		async getImages(id) {
			this.dataTyp = `lbxm-pic-${id}`;
			const { rows } = await listData({ dataModule: this.dataModule, dataTyp: this.dataTyp });
			this.fileList = rows.map((item) => ({
				dataDetailed: item.dataDetailed,
				dataModule: item.dataModule,
				dataTyp: item.dataTyp,
				url: item.dataDetailed,
				name: item.dataDetailed,
			}));
		},
	},
	mounted() {
		this.getLingBan();
		this.windowHeight = document.body.clientHeight;
	},
};
</script>
<style lang="scss" scoped>
@import '../zhfeHome/index.scss';
.title {
	position: fixed;
	top: 7vh;
	z-index: 99;
	height: 5.7vh;
	text-align: center;
	line-height: 5.7vh;
	font-size: 2.4vh;
	font-family: hy;
	margin-top: 1vh;
	text-align: left;
	padding-left: 3.5vw;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #e6598e;
}
.main {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding-bottom: 2vh;
	.container {
		flex: 1;
		min-width: 980px;
		max-width: 98%;
		width: 98%;
		margin: 0 auto;
		border: 2px solid rgba(255, 212, 228, 1);
		margin-top: 20px;
		padding-bottom: 20px;
		.awards-filter-wrap {
			width: 100%;
			border-bottom: 1px solid rgba(255, 212, 228, 1);
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;

			::v-deep .el-form {
				.el-form-item {
					padding-right: 45px;
					.el-form-item__label {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ce739c;
					}
				}

				.el-input__inner,
				.el-textarea__inner {
					width: initial;
				}
			}
		}
		.table-wrap {
			padding: 20px 20px 0;
		}
		::v-deep .el-table {
			margin: 0;
			background-color: transparent;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;
			&::-webkit-scrollbar {
				width: 0 !important;
			}
			&:before {
				background-color: transparent;
			}
			.el-table__header-wrapper {
				/* height: 35px; */
				tr {
					background-color: transparent;
					.el-table__cell {
						background-color: rgba(180, 75, 122, 0.7);
						padding: 0px;
						height: 35px;

						.cell {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}
			.el-table__row {
				background-color: rgba(180, 75, 122, 0.2);
			}
			.el-table__row--striped {
				background-color: rgba(180, 75, 122, 0.4);
				td.el-table__cell {
					background: transparent;
				}
			}
			th.el-table__cell.is-leaf {
				border-bottom: none;
			}
			td.el-table__cell {
				border-bottom: none;
			}
			.cell {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #fff;
			}
		}
		.delete {
			color: #73ff87;
		}
		.edit {
			color: #379aff;
			margin: 0;
		}
	}
}
.el-button--primary {
	/* width: 68px; */
	height: 38px;
	background: rgba(255, 212, 228, 0.4);
	border: 1px solid #ffd4e4;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #eea8c4;
	border-radius: 0px;
	&.other {
		clip-path: polygon(
			0 0,
			100% 0,
			100% 0,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0 calc(100% + 12px),
			0 calc(100% + 12px),
			-2px -2px
		);
		background: linear-gradient(-45deg, #ffd4e4 8px, rgba(255, 212, 228, 0.4) 0) bottom right,
			linear-gradient(45deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) bottom left,
			linear-gradient(135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top left,
			linear-gradient(-135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top right;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}
::v-deep .el-dialog {
	min-height: 491.08px;
	.el-dialog__body {
		padding: 0 50px 50px;
	}
	.el-upload--picture-card {
		background-color: transparent;
		border: 1px dashed #ffd4e4;
		.el-icon-plus {
			color: #ffd4e4;
		}
	}
	.el-dialog__footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
	}
	.close-dialog {
		position: absolute;
		top: -16px;
		right: -16px;
		cursor: pointer;
		z-index: 2001;
	}
	.dialog-title {
		margin: 0 auto;
		min-width: 240px;
		width: max-content;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 62px;
		background: #cd80a2;
		border-radius: 0px 0px 14px 14px;
		background-size: 16px 16px;
		border: 2px solid #fcd2e2;
		margin-bottom: 30px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}
	.el-button--primary {
		width: 180px;
		height: 44px;
		background: #002953;
		border: 1px solid #ffd4e4;
		border-radius: 2px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
	.el-button + .el-button {
		margin-left: 78px;
	}
}
.pagination-wrap {
	display: flex;
	justify-content: flex-end;
	padding-right: 18px;
	padding-top: 20px;
	.el-pagination__total {
		font-size: 12px;
		font-family: SimSun;
		font-weight: 400;
		color: #cceeff;
		line-height: 18px;
	}
}
::v-deep .demo-form-inline {
	.el-form-item {
		margin-bottom: 0px;

		.el-form-item__label {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd4e3;
		}
		.el-input__inner,
		.el-textarea__inner {
			/* width: 296px; */
			height: 38px;
			background: linear-gradient(270deg, rgba(205, 128, 162, 0), rgba(216, 149, 178, 0.17), rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			border-radius: 0;
			&::placeholder {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
			}
		}
	}
	.el-upload__tip {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffd4e3;
	}
	&.dialog-form-inline {
		.el-form-item {
			width: 100%;
			display: flex;
			margin-bottom: 20px;

			.el-form-item__content {
				flex: 1;
			}
		}
	}
}
::v-deep.delete-dialog {
	.el-dialog {
		min-height: 150px;
	}

	.dialog-content {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
}
</style>

<style>
.el-table--enable-row-hover .el-table__body tr:hover > td {
	background-color: rgba(180, 75, 122, 0.7) !important;
}
.pagination-wrap .el-pagination__total {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #cceeff;
	line-height: 18px;
}
.pagination-wrap .el-input__inner {
	background: rgba(9, 21, 45, 0.4);
	border: 1px solid #b44b7a;
	color: #ffffff;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
}
.pagination-wrap .btn-prev,
.pagination-wrap .btn-next {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.2) !important;
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}
.pagination-wrap .btn-prev:disabled,
.pagination-wrap .btn-next:disabled {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.4);
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}
.pagination-wrap .number {
	background-color: rgba(180, 75, 122, 0.4) !important;
	border: 1px solid #b44b7a !important;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	border-radius: 0px;
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
}
.pagination-wrap .active {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #ffffff !important;
	background: url('../../assets/image/zhfe/pagination_bg.png');
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border: none !important;
}
</style>